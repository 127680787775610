<template>
    <div>
        <el-button type="primary" size="default" @click="toHome">首页</el-button>
        <el-button type="primary" size="default" @click="toProject">项目管理</el-button>
        <el-button type="primary" size="default" @click="toDevice">设备定位</el-button>
        <el-button type="primary" size="default" @click="toInstall">安装计划</el-button>
        <el-button type="primary" size="default" @click="toInspect">巡检记录</el-button>
        <el-button type="primary" size="default" @click="toWork">工单管理</el-button>
        <el-button type="primary" size="default" @click="toReceive">收币记录</el-button>
        <el-button type="primary" size="default" @click="toBusiness">业务统计</el-button>
        <el-button type="primary" size="default" @click="toIncome">收益统计</el-button>
        <el-button type="primary" size="default" @click="toNode">节点模板</el-button>
        <el-button type="primary" size="default" @click="toSurvey">调研模板</el-button>
        <el-button type="primary" size="default" @click="toContract">合同模板</el-button>
        <el-button type="primary" size="default" @click="toSystem">系统日志</el-button>
        <el-button type="primary" size="default" @click="toProjectType">项目类型</el-button>
        <el-button type="primary" size="default" @click="toDictIndex">字典</el-button>
    </div>
</template>

<script>
export default{
    name:"test",
    methods:{
        toHome(){
            this.$router.push('/charging-station/index')
        },
        toProject(){
            this.$router.push('/charging-station/project')
        },
        toDevice(){
            this.$router.push('/charging-station/device-location')
        },
        toInstall(){
            this.$router.push('/charging-station/installation-plan')
        },
        toInspect(){
            this.$router.push('/charging-station/inspection-record')
        },
        toWork(){
            this.$router.push('/charging-station/work-order')
        },
        toReceive(){
            this.$router.push('/charging-station/receive-coin')
        },
        toBusiness(){
            this.$router.push('/charging-station/business-statistics')
        },
        toIncome(){
            this.$router.push('/charging-station/income-statistics')
        },
        toNode(){
            this.$router.push('/charging-station/node-template')
        },
        toSurvey(){
            this.$router.push('/charging-station/survey-template')
        },
        toContract(){
            this.$router.push('/charging-station/contract-template')
        },
        toSystem(){
            this.$router.push('/charging-station/system-log')
        },
        toProjectType() {
          this.$router.push('/charging-station/project-type')
        },
        toDictIndex() {
          this.$router.push('/charging-station/dict-type')
        }
    }
}
</script>

<style scoped>

</style>
